import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

/**
 * Footer component displays the client and server version numbers.
 *
 * @param {Object} props - The props of the component.
 * @param {string} props.clientVersion - The version number of the client.
 * @param {string} props.serverVersion - The version number of the server.
 * @returns {React.ReactElement} The Footer component.
 */

const Footer = ({ clientVersion, serverVersion }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div id='footer' style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
      <span style={{ color: theme.palette.primary.main }}>
        {t('footer.client_version')}: {clientVersion}, {t('footer.server_version')}: {serverVersion}
      </span>
    </div>
  );
};

export default Footer;
