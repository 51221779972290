import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import nb from './translations/nb.json';
import en from './translations/en.json';

const resources = {
  en: {
    translation: en,
  },
  nb: {
    translation: nb,
  },
};

// Check if there's a chosen language in localStorage
const savedLanguage = localStorage.getItem('i18nextLng');

// If no language is set in localStorage, default to 'no'
if (!savedLanguage) {
  localStorage.setItem('i18nextLng', 'nb');
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: savedLanguage || 'nb',
    fallbackLng: 'nb',
    debug: false,
    backend: {
      loadPath: '/translations/{{lng}}.json',
    },
    resources,
  });

export default i18n;
