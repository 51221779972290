import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewOrderProductForm from './NewOrderProductForm';
import NewOrderDeliveryForm from './NewOrderDeliveryForm';
import FormContext from '../../context/FormContext';
import { FormNavButtons } from '../../components/FormNavButtons';
import { OrderSuccess } from '../../components/OrderSuccess';
import NewOrderConfirmation from './NewOrderConfirmation';
import { validateForm, validateProducts } from '../../common/validators';
import LoadingContext from '../../context/LoadingContext';
import { useTheme } from '@mui/material';

/**
 * NewOrder is a component that manages the creation of a new order.
 * It handles different stages of the order process, including product selection, delivery details, and order confirmation.
 * This component integrates with various contexts like FormContext, AuthContext, and LoadingContext for state management and API interactions.
 *
 * @returns {React.ReactElement} The NewOrder component rendering the current page of the order process or the order success screen.
 */
const NewOrder = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [displayOrderSuccess, setDisplayOrderSuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);
  const { form, submitForm, resetOrder } = useContext(FormContext);
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const openPrevPage = () => setCurrentPage(currentPage - 1);
  const openNextPage = () => {
    if (currentPage === 2) {
      confirmOrder();
    } else setCurrentPage(currentPage + 1);
  };

  const confirmOrder = async () => {
    setIsLoading(true);
    const orderId = await submitForm();
    setIsLoading(false);

    setOrderId(orderId);
    setDisplayOrderSuccess(true);
  };

  const startNewOrder = () => {
    resetOrder();
    setCurrentPage(0);
    setDisplayOrderSuccess(false);
    setOrderId(null);
  };

  if (displayOrderSuccess) return <OrderSuccess orderId={orderId} startNewOrder={startNewOrder} />;

  const pages = [
    {
      title: t('order.product_order'),
      formComponent: <NewOrderProductForm />,
      canOpenNextPage: validateProducts(form.products),
    },
    {
      title: t('order.delivery_details'),
      formComponent: <NewOrderDeliveryForm />,
      canOpenNextPage: validateForm(form),
    },
    {
      title: t('order.confirm_order'),
      formComponent: (
        <NewOrderConfirmation
          onEditProducts={() => setCurrentPage(0)}
          onEditDelivery={() => setCurrentPage(1)}
          onEditComment={() => setCurrentPage(1)}
        />
      ),
      canOpenNextPage: true,
    },
  ];

  const titleStyle = {
    marginBottom: '2em',
    color: theme.palette.primary.main,
    marginTop: '100px',
  };

  return pages.map((page, i) => (
    <React.Fragment key={page.title}>
      <h1 style={titleStyle}>{page.title}</h1>
      {page.formComponent}
      <FormNavButtons
        page={i}
        isLast={i === pages.length - 1}
        openPrevPage={openPrevPage}
        canOpenNextPage={page.canOpenNextPage}
        openNextPage={openNextPage}
      />
    </React.Fragment>
  ))[currentPage];
};

export default NewOrder;
