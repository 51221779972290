import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddressAutoComplete from './AddressAutoComplete';
import FormContext from '../context/FormContext';
import { Checkbox, FormControlLabel, TextField, Button } from '@mui/material';
import DistrictSelect from './DistrictSelect';
import manualPickupAddress, { addressMatchManualPickup } from '../common/manualPickupAddress';
import { validateAddressName, validateName, validatePhoneNumber } from '../common/validators';
import { useTheme } from '@mui/material/styles';

/**
 * NewAddressForm component provides a form for entering a new address.
 * It includes fields for contact person, mobile number, and address details.
 * Additionally, it offers an option for manual pickup with custom address logic.
 *
 * @param {Object} props - The props of the component.
 * @param {Function} props.onChange - Function to handle changes in the form fields.
 * @returns {React.ReactElement} The NewAddressForm component.
 */
const NewAddressForm = ({ onChange }) => {
  const { t } = useTranslation();
  const { form, dispatch } = useContext(FormContext);
  const [showAddressNameHelperText, setShowAddressNameHelperText] = useState(false);

  useEffect(() => {
    if (addressMatchManualPickup(form.address)) {
      dispatch({ useManualPickup: true });
    }
  }, [form.address, dispatch]);

  const onChangeManualPickup = (ev) => {
    dispatch({ useManualPickup: ev.target.checked });
  };

  const clean = () => {
    dispatch({
      name: '',
      phone: '',
      address: {
        address: '',
        name: '',
        postalTown: '',
        postalCode: '',
      },
      district: '',
    });
  };

  const customTheme = useTheme();

  return (
    <>
      <Button onClick={clean} variant='outlined'>
        {t('new_address_form.clear_fields')}
      </Button>
      <TextField
        label={t('new_address_form.contact_person')}
        sx={{
          '& .MuiInputLabel-root': {
            color: customTheme.palette.text.primary,
          },
        }}
        name='name'
        value={form.name}
        placeholder={t('new_address_form.contact_person_placeholder')}
        onChange={onChange}
        required
        error={!validateName(form.name)}
        helperText={form.name !== '' && !validateName(form.name) && t('new_address_form.name_validation_error')}
      />
      <TextField
        label={t('new_address_form.phone_number')}
        sx={{
          '& .MuiInputLabel-root': {
            color: customTheme.palette.text.primary,
          },
        }}
        name='phone'
        value={form.phone}
        placeholder={t('new_address_form.phone_number_placeholder')}
        onChange={onChange}
        required
        error={!validatePhoneNumber(form.phone)}
        helperText={!validatePhoneNumber(form.phone) && t('new_address_form.phone_validation_error')}
      />
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              'color': customTheme.palette.text.primary,
              '&.Mui-checked': {
                color: customTheme.palette.text.primary,
              },
            }}
            checked={form.useManualPickup}
            onChange={onChangeManualPickup}
          />
        }
        label={t('new_address_form.manual_pickup_label')}
        sx={{ color: customTheme.palette.text.primary }}
      />
      {form.useManualPickup && (
        <DistrictSelect name='manualPickupDistrict' value={form.manualPickupDistrict} form={form} onChange={onChange} />
      )}
      {!form.useManualPickup && (
        <DistrictSelect name='district' value={form.district} form={form} onChange={onChange} />
      )}
      <TextField
        label={t('new_address_form.recipient_location')}
        name='addressName'
        value={(form.useManualPickup && manualPickupAddress.name) || form.address.name}
        placeholder={t('new_address_form.recipient_location_placeholder')}
        onChange={onChange}
        helperText={showAddressNameHelperText && t('new_address_form.recipient_location_helper')}
        onFocus={() => setShowAddressNameHelperText(true)}
        onBlur={() => setShowAddressNameHelperText(false)}
        disabled={form.useManualPickup}
        required
        error={!form.useManualPickup && !validateAddressName(form.address.name)}
      />
      <AddressAutoComplete
        name='address'
        value={form.address}
        placeholder={t('new_address_form.delivery_address')}
        onChange={onChange}
        forcedAddress={form.useManualPickup && manualPickupAddress}
      />
    </>
  );
};

export default NewAddressForm;
