import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const commonSettings = {
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
};

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3e3844',
    },
    secondary: {
      main: '#e7ab44',
    },
    background: {
      default: '#f0f0f0',
      grey1: '#e0e0e0',
      grey2: '#f0f0f0',
      paper: '#f7f7f7',
      blank: '#ffffff',
    },
    text: {
      primary: '#3e3844',
    },
    link: {
      main: '#e7ab44',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          'color': '#e7ab44',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f0f0f0',
          color: '#3e3844',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            'backgroundColor': '#e7ab44',
            'color': '#fff',
            'boxShadow': '0 4px 8px rgba(0, 0, 0, 0.2)',
            'borderRadius': '0.25rem',
            '&:hover': {
              backgroundColor: '#d69322',
              color: '#fff',
            },
          },
        },
      },
    },
  },
  ...commonSettings,
});

export const EmailButton = styled(Button)(({ theme }) => ({
  marginLeft: 1,
  transition: 'background-color 0.3s, border-color 0.3s',
  width: 'auto',
}));

export default theme;
