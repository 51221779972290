import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import districts from '../common/districts';
import { validateDistrict } from '../common/validators';

/**
 * A Material-UI based dropdown component for selecting a district.
 * It renders a list of options based on the provided districts array.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name attribute for the Select, used for form handling.
 * @param {string} props.value - The currently selected value in the dropdown.
 * @param {Object} props.form - The form state containing district and possibly other fields.
 * @param {Function} props.onChange - The function to call when the value of the dropdown changes.
 * @returns {React.ReactElement} A Select dropdown component for district selection.
 */
const DistrictSelect = ({ name, value, form, onChange }) => {
  const { t } = useTranslation();
  const isError = !value || !validateDistrict(value);

  return (
    <FormControl fullWidth required error={isError}>
      <InputLabel id='district-select-label'>{t('district_select.label')}</InputLabel>
      <Select
        labelId='district-select-label'
        id='district-select'
        label={t('district_select.label')}
        name={name}
        value={value}
        onChange={onChange}>
        {districts.map((district) => (
          <MenuItem value={district} key={district}>
            {district}
          </MenuItem>
        ))}
      </Select>
      {isError && (
        <FormHelperText>
          {!value ? t('district_select.error_no_selection') : t('district_select.error_invalid_selection')}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DistrictSelect;
