import React, { useState, useContext, useEffect } from 'react';
import Datepicker from '../../components/Datepicker';
import { useTranslation } from 'react-i18next';
import FormContext from '../../context/FormContext';
import {
  Box,
  Stack,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Alert,
  Typography,
  Container,
  Paper,
} from '@mui/material';
import NewAddressForm from '../../components/NewAddressForm';
import { add, isBefore, startOfDay, isWeekend, addDays } from 'date-fns';
import { useTheme } from 'styled-components';

const wrapperStyle = {
  maxWidth: '400px',
  margin: '0 auto',
};

// Function to add business days, excluding weekends
const addBusinessDays = (date, days) => {
  let result = new Date(date);
  let addedDays = 0;
  while (addedDays < days) {
    result = addDays(result, 1);
    if (!isWeekend(result)) {
      addedDays += 1;
    }
  }
  return result;
};

/**
 * NewOrderDeliveryForm component provides a form for entering delivery details for a new order.
 * It includes inputs for the delivery date, address, and an optional comment section.
 * The component uses FormContext to manage the state and validation of form fields.
 *
 * @returns {React.ReactElement} The NewOrderDeliveryForm component.
 */
export default function NewOrderDeliveryForm() {
  const { t } = useTranslation();
  const { form, dispatch } = useContext(FormContext);
  const [errorMessage, setErrorMessage] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState('standard');
  const [showCommentHelperText, setShowCommentHelperText] = useState(false);

  useEffect(() => {
    const defaultDate = addBusinessDays(new Date(), 3);
    if (!form.deliveryDate) {
      dispatch({ deliveryDate: defaultDate });
    }

    const formDateStart = startOfDay(new Date(form.deliveryDate));
    const defaultDateStart = startOfDay(defaultDate);

    if (deliveryOption === 'standard' && isBefore(formDateStart, defaultDateStart)) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  }, [form.deliveryDate, dispatch, deliveryOption]);

  const addressIsValid =
    form &&
    form.name &&
    form.phone &&
    ((form.useManualPickup && form.manualPickupDistrict) ||
      (form.address &&
        form.address.name &&
        form.address.address &&
        form.address.postalCode &&
        form.address.postalTown));

  const handleRadioChange = (event) => {
    const option = event.target.value;
    setDeliveryOption(option);

    const standardDeliveryDate = addBusinessDays(new Date(), 3);
    const ekspressDeliveryDate = add(new Date(), { days: 1 });

    if (option === 'standard') {
      dispatch({ type: 'setDeliveryDate', deliveryDate: standardDeliveryDate });
    }

    if (option === 'ekspress') {
      dispatch({ type: 'setDeliveryDate', deliveryDate: ekspressDeliveryDate });
    }

    dispatch({ type: 'setIsUrgent', isUrgent: option === 'ekspress' });
  };

  const onChangeDelivery = (ev) => {
    dispatch({ [ev.target.name]: ev.target.value });
  };

  const onChange = (ev) => {
    if (ev.target.name === 'addressName') {
      const newAddress = { ...form.address, name: ev.target.value };
      dispatch({ address: newAddress });
      return;
    }
    dispatch({ [ev.target.name]: ev.target.value });
  };

  const theme = useTheme();

  const formContainerStyle = {
    background: theme.palette.background.blank,
    padding: '30px 0px',
    borderRadius: '5px',
    width: '100%',
    maxWidth: { xs: '100%', sm: '70%', md: '40%' },
    margin: 'auto',
  };

  return (
    <Paper elevation={8} sx={formContainerStyle}>
      <Box style={wrapperStyle}>
        <Stack spacing={3}>
          <Datepicker
            label={t('delivery_form.desired_delivery_date')}
            value={form.deliveryDate}
            name='deliveryDate'
            onChange={onChangeDelivery}
            required
            error={!!errorMessage}
            helperText={errorMessage || t('delivery_form.desired_delivery_date')}
          />
          {errorMessage && <Alert severity='error'>{t('delivery_form.error_message')}</Alert>}
          <Box>
            <RadioGroup defaultValue='standard'>
              <FormControlLabel
                value='standard'
                control={<Radio checked={deliveryOption === 'standard'} onChange={handleRadioChange} />}
                label={
                  <Box>
                    <Typography variant='subtitle1' component='span'>
                      {t('delivery_form.standard_delivery')}
                    </Typography>
                  </Box>
                }
              />
              <Typography
                variant='body2'
                color='textSecondary'
                sx={{ alignItems: 'flex-start', textAlign: 'left', width: '100%' }}>
                {t('delivery_form.standard_delivery_time')}
              </Typography>
              <FormControlLabel
                value='ekspress'
                control={<Radio checked={deliveryOption === 'ekspress'} onChange={handleRadioChange} />}
                label={
                  <Box>
                    <Typography variant='subtitle1' component='span'>
                      {t('delivery_form.express_delivery')}
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
            <Typography
              variant='body2'
              color='textSecondary'
              sx={{ alignItems: 'flex-start', textAlign: 'left', width: '100%' }}>
              {t('delivery_form.express_delivery_time')}
            </Typography>
          </Box>
          <NewAddressForm onChange={onChange} />
          <TextField
            multiline
            minRows={3}
            name='comment'
            label={t('delivery_form.comment_optional')}
            value={form.comment}
            onChange={onChange}
            helperText={showCommentHelperText && t('delivery_form.comment_helper_text')}
            onFocus={() => setShowCommentHelperText(true)}
            onBlur={() => setShowCommentHelperText(false)}
          />
        </Stack>
      </Box>
    </Paper>
  );
}
