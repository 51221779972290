import React, { useContext } from 'react';
import { Button, TextField, Box, Stack, Divider, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { nb, enGB } from 'date-fns/locale'; // Import both locales
import FormContext from '../../context/FormContext';
import AuthContext from '../../context/AuthContext';
import formatAddress from '../../common/formatAddress';
import EmailRecipients from '../../components/EmailRecipients';
import { useTheme } from 'styled-components';

/**
 * Formats a JavaScript Date object into a human-readable string in the selected locale.
 * It formats the date to display the weekday, year, month, and day in a long format with the first letter capitalized.
 *
 * @param {Date} date - The Date object to be formatted.
 * @param {string} language - The current language (e.g., 'no' for Norwegian or 'en' for English).
 * @returns {string} A string representing the formatted date.
 */
const formatDate = (date, language) => {
  const locale = language === 'no' ? nb : enGB;
  const formattedDate = format(date, 'PPPP', { locale });
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

/**
 * NewOrderConfirmation component displays a summary of the new order details.
 * It allows editing of products, delivery information, and comments.
 * This component also handles adding and removing CC email addresses for order confirmation.
 *
 * @param {Object} props - The props of the component.
 * @param {Function} props.onEditProducts - Function to handle the editing of products.
 * @param {Function} props.onEditDelivery - Function to handle the editing of delivery details.
 * @param {Function} props.onEditComment - Function to handle the editing of the order comment.
 * @returns {React.ReactElement} The NewOrderConfirmation component.
 */
const NewOrderConfirmation = ({ onEditProducts, onEditDelivery, onEditComment }) => {
  const { t, i18n } = useTranslation();
  const { form, dispatch } = useContext(FormContext);
  const { auth } = useContext(AuthContext);
  const theme = useTheme();

  const currentLanguage = i18n.language === 'no' ? 'no' : 'en';

  const onAddCC = (email) => {
    dispatch({
      extraEmailRecipients: [...form.extraEmailRecipients, email],
    });
  };

  const onRemoveCCIndex = (index) => {
    const newRecipients = form.extraEmailRecipients.filter((_, i) => i !== index);
    dispatch({
      extraEmailRecipients: newRecipients,
    });
  };

  const formContainerStyle = {
    background: theme.palette.background.blank,
    padding: '30px 0px 30px 0px',
    borderRadius: '5px',
    maxWidth: { xs: '100%', sm: '70%', md: '40%' },
    alignItems: 'center',
    margin: 'auto',
  };

  return (
    <Paper elevation={8} sx={formContainerStyle}>
      <Stack
        sx={{ width: 'auto', margin: '0 10px 0 10px' }}
        alignItems='stretch'
        divider={<Divider orientation='horizontal' flexItem />}
        spacing={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack sx={{ width: '100px' }} justifyContent='center'>
            <p style={{ color: theme.palette.primary.main }}>{t('order_confirmation.products')}</p>
            <Button variant='outlined' onClick={onEditProducts}>
              {t('order_confirmation.edit')}
            </Button>
          </Stack>
          <Stack sx={{ width: '250px', textAlign: 'start' }}>
            {form.products.map((product) => (
              <p style={{ color: theme.palette.primary.main }} key={product.name}>
                {product.count}x {t(`orderUnits.${product.name}`)} {t(`sizes.${product.size}`)}
              </p>
            ))}
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack sx={{ width: '100px' }} justifyContent='center'>
            <p style={{ color: theme.palette.primary.main }}>{t('order_confirmation.delivery')}</p>
            <Button variant='outlined' onClick={onEditDelivery}>
              {t('order_confirmation.edit')}
            </Button>
          </Stack>
          <Stack sx={{ width: '250px', textAlign: 'start' }}>
            <p style={{ color: theme.palette.primary.main }}>{formatDate(form.deliveryDate, currentLanguage)}</p>
            <TextField
              sx={{
                '& .MuiInputBase-input': {
                  color: theme.palette.primary.main,
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.primary.main,
                },
              }}
              multiline
              disabled
              value={formatAddress(form)}
            />
          </Stack>
        </Box>
        {form.comment && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack sx={{ width: '100px' }} justifyContent='center'>
              <p style={{ color: theme.palette.primary.main }}>{t('order_confirmation.comment')}</p>
              <Button onClick={onEditComment} variant='outlined'>
                {t('order_confirmation.edit')}
              </Button>
            </Stack>
            <Stack sx={{ width: '250px', textAlign: 'start' }}>
              <TextField
                sx={{
                  '& .MuiInputBase-input': {
                    color: theme.palette.primary.main,
                  },
                  '& .MuiInputLabel-root': {
                    color: theme.palette.primary.main,
                  },
                }}
                multiline
                minRows={3}
                disabled
                value={form.comment}
              />
            </Stack>
          </Box>
        )}
        <Stack>
          <p style={{ color: theme.palette.primary.main }}>{t('order_confirmation.order_confirmation_sent_to')}</p>
          <EmailRecipients
            to={auth.email}
            cc={form.extraEmailRecipients}
            onAddCC={onAddCC}
            onRemoveCCIndex={onRemoveCCIndex}
          />
          <p style={{ color: theme.palette.primary.main }}>{t('order_confirmation.note')} </p>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default NewOrderConfirmation;
