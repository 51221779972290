import React, { useContext, useState, useEffect } from 'react';
import Delete from '@mui/icons-material/DeleteForever';
import styled from 'styled-components';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useTranslation } from 'react-i18next';
import FormContext from '../context/FormContext';
import { Button, CardActions, IconButton, ListItem, ListItemText, CssBaseline, Box, Chip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const NumberButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background: ${(props) =>
    props.disabled ? props.theme.palette.background.grey1 : props.theme.palette.secondary.main};
  color: ${(props) => (props.disabled ? props.theme.palette.primary.main : props.theme.palette.background.blank)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;

   &:hover {
    cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
    background: ${(props) => !props.disabled && props.theme.palette.secondary.dark};
    box-shadow: ${(props) => !props.disabled && '0px 4px 6px rgba(0, 0, 0, 0.25)'};
  }
  }

  @media (max-width: 600px) {
    width: 50px;
    height: 40px;
  }
`;

const NumberInput = styled.input`
  width: 40px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 1em;
  color: ${(props) => props.theme.palette.primary.main};
  background: ${(props) => props.theme.palette.background.blank};
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.palette.primary.main};
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 3px #1976d2;
  }
  @media (max-width: 600px) {
    width: 50px;
    height: 40px;
  }
`;

const ButtonInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;

  @media (max-width: 600px) {
    width: 200px;
  }
`;

const RightAlignContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;

const iconWrapperStyle = {
  position: 'absolute',
  right: '0',
};

const listProductStyle = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  paddingBottom: '0',
  margin: '0',
};

const cardActionStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

const addToCartButtonStyle = {
  position: 'absolute',
  height: '25%',
  width: '50%',
  top: '90%',
  left: '15px',
};

/**
 * OvaProductWithQuantity component displays a product with the ability to adjust its quantity and add or remove it from a form context.
 * It provides buttons for increasing, decreasing, or setting a specific quantity, as well as adding the product to a form.
 *
 * @param {Object} props - The props of the component.
 * @param {string} props.id - The unique identifier of the product.
 * @param {string} props.name - The name of the product.
 * @param {string} props.description - A description of the product.
 * @param {number} props.initialCount - The initial quantity count of the product.
 * @param {boolean} props.addOnClick - Indicates whether the add button should be displayed.
 * @returns {React.ReactElement} The OvaProductWithQuantity component.
 */
const OvaProductWithQuantity = ({ id, name, description, initialCount, size, addOnClick, sx }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(initialCount);
  const { addProduct, removeProduct, setProductCount, form } = useContext(FormContext);
  const [isAdded, setIsAdded] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const productExists = form.products.some((product) => product.id === id);
    setIsAdded(productExists);
  }, [form.products, id]);

  const updateCount = (newCount) => {
    setCount(newCount);
    setProductCount(id, newCount);
  };

  const handleAddProduct = () => {
    addProduct(id, name, count, description, size);
    setIsAdded(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box sx={sx}>
        <ListItem key={id} alignItems='flex-start' sx={listProductStyle}>
          <ListItemText
            primary={t(`orderUnits.${name}`)}
            sx={{
              '& .MuiListItemText-primary': {
                color: theme.palette.primary.main,
                marginBottom: '0px',
                height: '30px',
                fontSize: '20px',
              },
            }}
          />
          {size && (
            <Chip
              variant='filled'
              label={t(`sizes.${size}`)}
              color='primary'
              sx={{ color: theme.palette.background.blank, opacity: '0.5', fontSize: 14 }}
            />
          )}
          <div>
            <ListItemText
              primary={t(`descriptions.${name}`)}
              sx={{
                '& .MuiListItemText-primary': {
                  color: theme.palette.primary.main,
                },
              }}
            />
          </div>
          <RightAlignContainer>
            <CardActions sx={cardActionStyle}>
              {addOnClick ? (
                <Box
                  sx={{
                    marginTop: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleAddProduct}
                    sx={addToCartButtonStyle}
                    endIcon={<AddShoppingCartIcon />}
                    disabled={isAdded}>
                    {isAdded ? t('product.product_added') : t('product.add_to_cart')}
                  </Button>
                </Box>
              ) : (
                <>
                  <ButtonInputContainer>
                    <NumberButton disabled={count <= 1} onClick={() => updateCount(count - 1)}>
                      -
                    </NumberButton>
                    <NumberInput anySelected={count > 1} type='text' value={count || 0} disabled />
                    <NumberButton onClick={() => updateCount(count + 1)}>+</NumberButton>
                  </ButtonInputContainer>
                  <div style={iconWrapperStyle}>
                    <IconButton variant='outlined' size='large' onClick={() => removeProduct(id)}>
                      <Delete fontSize='large' />
                    </IconButton>
                  </div>
                </>
              )}
            </CardActions>
          </RightAlignContainer>
        </ListItem>
      </Box>
    </ThemeProvider>
  );
};

export default OvaProductWithQuantity;
