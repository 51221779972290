import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { FormContextProvider } from './context/FormContext';
import { LoadingContextProvider } from './context/LoadingContext';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import theme from './theme';
import './i18n';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <LoadingContextProvider>
          <AuthContextProvider>
            <FormContextProvider>
              <App />
            </FormContextProvider>
          </AuthContextProvider>
        </LoadingContextProvider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
);
