import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { CircleFlagLanguage } from 'react-circle-flags';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const LanguageButton = ({ languageCode, selectedLanguage, changeLanguage }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0px',
        marginLeft: '0.5em',
        cursor: 'pointer',
        transform: selectedLanguage === languageCode ? 'scale(1.1)' : 'scale(1)',
        borderRadius: '50%',
        border: selectedLanguage === languageCode ? `3px solid ${theme.palette.primary.main}` : 'none',
        transition: 'transform 0.3s ease, border 0.3s ease',
      }}
      onClick={() => changeLanguage(languageCode)}>
      <CircleFlagLanguage languageCode={languageCode} height='35' />
    </Box>
  );
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng') || 'nb');

  useEffect(() => {
    // Check if there's a language set in localStorage and apply it
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem('i18nextLng', language); // Save selected language to localStorage
  };

  const supportedLanguages = Object.keys(i18n.options.resources);

  return (
    <>
      {supportedLanguages.map((language) => (
        <LanguageButton
          key={language}
          languageCode={language}
          selectedLanguage={selectedLanguage}
          changeLanguage={changeLanguage}
        />
      ))}
    </>
  );
};

export default LanguageSelector;
