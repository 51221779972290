import React, { useContext, useState, useRef, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import FormContext from '../context/FormContext';
import logo from '../images/eyasys-logo.svg';
import { Box, Button, Paper, Collapse } from '@mui/material';
import { useTheme } from 'styled-components';
import { EmailButton } from '../theme';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

/**
 * Logo component displays the company logo with different styles based on the application environment.
 * In production, it shows only the logo. In test and development environments, it additionally displays environment labels.
 *
 * @returns {React.ReactElement} The Logo component.
 */
const Logo = () => {
  const imageStyle = {
    height: '40px',
    padding: '15px 0 0 15px',
  };

  const testStyle = {
    backgroundColor: 'yellow',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const devStyle = {
    backgroundColor: 'lightGreen',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const LogoImg = <img src={logo} alt='Eyasys Logo' style={imageStyle} />;

  if (process.env.APP_ENV === 'prod' && process.env.NODE_ENV === 'production') {
    return <>{LogoImg}</>;
  } else if (process.env.APP_ENV === 'test') {
    return (
      <div style={testStyle}>
        {LogoImg}
        <p>TEST</p>
      </div>
    );
  } else {
    return (
      <div style={devStyle}>
        {LogoImg}
        <p>DEV</p>
      </div>
    );
  }
};

/**
 * Header component displays the application's header.
 * It shows the logo and, if the user is logged in, the user's email and a logout button.
 *
 * @returns {React.ReactElement} The Header component.
 */
const Header = () => {
  const { t } = useTranslation();
  const { auth, logout } = useContext(AuthContext);
  const { resetForm } = useContext(FormContext);
  const [showLogout, setShowLogout] = useState(false);
  const logoutButtonRef = useRef(null);

  const handleToggle = () => {
    setShowLogout((prev) => !prev);
  };

  useEffect(() => {
    if (showLogout) {
      const timer = setTimeout(() => {
        setShowLogout(false);
      }, 3000); // Close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [showLogout]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutButtonRef.current && !logoutButtonRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [logoutButtonRef]);

  const theme = useTheme();

  const wrapperStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
    alignItems: 'center',
  };

  const headerStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'fixed',
    top: '0',
    background: theme.palette.background.default,
    opacity: '1',
    zIndex: '200',
    maxHeight: '70px',
    padding: '10px 20px',
    alignItems: 'center',
  };

  const flagStyle = (isSelected) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    marginLeft: '10px',
    cursor: 'pointer',
    transform: isSelected ? 'scale(1.1)' : 'scale(1)',
    borderRadius: '50%',
    border: isSelected ? '3px solid #3f51b5' : '3px solid transparent',
    transition: 'transform 0.3s ease, border 0.3s ease',
  });

  const languageContainer = {
    marginRight: '30px',
    display: 'flex',
    flexDirection: 'row',
  };

  if (auth.isLoggedIn) {
    return (
      <Box component={Paper} elevation={4} sx={headerStyle}>
        <Logo />
        <div style={wrapperStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={languageContainer}>
              <LanguageSelector sx={flagStyle} />
            </Box>
            {auth.isLoggedIn && (
              <>
                <EmailButton
                  onClick={handleToggle}
                  color='inherit'
                  sx={{ borderColor: 'primary.main', height: '36px', marginLeft: '5px', marginRight: '30px' }}
                  variant='outlined'>
                  {auth.email}
                </EmailButton>
                <Collapse in={showLogout} orientation='horizontal' timeout='auto'>
                  <Button
                    onClick={logout}
                    color='inherit'
                    variant='outlined'
                    sx={{
                      marginLeft: 1,
                      borderColor: 'primary.main',
                      whiteSpace: 'nowrap',
                      transition: 'background-color 0.3s, border-color 0.3s',
                      width: 'auto',
                      marginRight: '30px',
                    }}>
                    {t('header.logout')}
                  </Button>
                </Collapse>
              </>
            )}
          </div>
        </div>
      </Box>
    );
  }
};

export default Header;
