import React, { useContext, useState, useEffect, Fragment } from 'react';
import { getAllAvailableProducts } from '../../common/ova_constants';
import OvaProductWithQuantity from '../../components/OvaProductWithQuantity';
import FormContext from '../../context/FormContext';
import '../../common/groupBy';
import {
  Container,
  List,
  ListSubheader,
  Badge,
  Box,
  Card,
  CardMedia,
  IconButton,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { productImage } from '../../common/ova_constants';

const wrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const availableProductsWrapperStyle = {
  'display': 'grid',
  'gap': '60px',
  'paddingTop': '1em',
  'margin': 0,
  'justifyContent': 'space-evenly',
  'gridTemplateColumns': 'repeat(3, 1fr)',
  '@media (max-width: 1780px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@media (max-width: 1000px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
};

const selectedProductsStyle = {
  maxHeight: '240px',
  minHeight: '190px',
  width: '270px',
  display: 'flex',
};

const availableProductsStyle = {
  height: '200px',
  display: 'flex',
  justifyContent: 'space-between',
};

const selectedProductsWrapperStyle = (isVisible) => ({
  'position': 'fixed',
  'right': 0,
  'top': '6%',
  'transform': isVisible ? 'translateX(0)' : 'translateX(100%)',
  'display': 'flex',
  'flexDirection': 'column',
  'width': '17%',
  'flexBasis': '460px',
  'height': '94%',
  'margin': 0,
  'overflowY': 'scroll',
  'zIndex': 99,
  'transition': 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  'opacity': isVisible ? 1 : 0,
  'pointerEvents': isVisible ? 'auto' : 'none',
  'scrollbarWidth': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '@media (max-width: 600px)': {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    top: '0',
    zIndex: '1000',
  },
});
const cardStyleAvailable = {
  height: '480px',
  width: '360px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderBottom: 'solid 1px #ebbb69',
};

const cartButtonStyle = {
  'position': 'fixed',
  'zIndex': 2000,
  'display': 'none',
  '@media (max-width: 600px)': {
    width: '100%',
    height: '8%',
    borderRadius: 0,
    bottom: '0',
    top: 'unset',
    right: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

/**
 * NewOrderProductForm component displays a selection of available products and a list of selected products for a new order.
 * It allows users to add available products to their order and view the products currently in their shopping cart.
 * The component utilizes FormContext to access and update the selected products in the form.
 *
 * @returns {React.ReactElement} The NewOrderProductForm component.
 */

const cardImageStyle = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '240px',
};

const NewOrderProductForm = () => {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  const selectedProducts = form.products;
  const availableProducts = getAllAvailableProducts();
  const [isCartVisible, setIsCartVisible] = useState(true);
  const theme = useTheme();

  const headerStyle = {
    fontSize: '1.5em',
    textAlign: 'start',
    position: 'relative',
    top: '10px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '20px',
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setIsCartVisible(false);
      } else {
        setIsCartVisible(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCartVisibility = () => {
    setIsCartVisible(!isCartVisible);
  };

  const isProductInCart = (productId) => {
    return selectedProducts.some((product) => product.id === productId);
  };

  const totalItemsInCart = selectedProducts.reduce((sum, product) => sum + product.count, 0);

  return (
    <Container sx={wrapperStyle}>
      <Box sx={availableProductsWrapperStyle}>
        {availableProducts.groupBy('type').map((productGroup) => (
          <Fragment key={productGroup.groupList}>
            {productGroup.groupList.map((product) => (
              <Card component={Paper} elevation={8} sx={cardStyleAvailable} key={product.id}>
                <CardMedia
                  component='img'
                  height='194'
                  image={productImage[product.id]}
                  alt={product.name}
                  sx={cardImageStyle}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <OvaProductWithQuantity
                    id={product.id}
                    name={product.name}
                    size={product.size}
                    description={product.description}
                    initialCount={product.count}
                    addOnClick
                    sx={availableProductsStyle}
                  />
                </Box>
              </Card>
            ))}
          </Fragment>
        ))}
      </Box>
      <IconButton
        onClick={toggleCartVisibility}
        sx={{
          ...cartButtonStyle,
          'backgroundColor': theme.palette.background.default,
          'color': theme.palette.primary.main,
          '&:hover': { backgroundColor: theme.palette.background.default },
        }}>
        <Badge badgeContent={totalItemsInCart} color='primary' sx={{ marginRight: '8px' }}>
          <ShoppingCartIcon />
        </Badge>
        <Typography sx={{ color: 'primary' }}>Handlevogn</Typography>
      </IconButton>
      <Container component={Paper} elevation={8} sx={selectedProductsWrapperStyle(isCartVisible)}>
        <List subheader={<ListSubheader sx={headerStyle}>{t('product_form.shopping_cart')}</ListSubheader>}>
          <Divider component='li' />
          {selectedProducts.map((product) => (
            <Box key={product.id}>
              <OvaProductWithQuantity
                id={product.id}
                name={product.name}
                size={product.size}
                initialCount={product.count}
                sx={selectedProductsStyle}
              />
              <Divider component='li' />
            </Box>
          ))}
        </List>
      </Container>
    </Container>
  );
};

export default NewOrderProductForm;
