import React, { createContext, useState, useContext, useEffect } from 'react';
import API from '../api/api';

/**
 * AuthContext is a React context for managing authentication-related data.
 * It holds information about the user's authentication state, including the login status, token, and error messages.
 */
const AuthContext = createContext();

/**
 * AuthContextProvider is a provider component for AuthContext.
 * It wraps its children components and provides them with authentication-related data and functions.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components that will consume the context.
 * @returns {React.ReactElement} The AuthContextProvider component.
 */

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
};

const initialState = {
  email: '',
  isLoggedIn: false,
  loginErrorMessage: null,
  token: null,
};

export const AuthContextProvider = ({ children }) => {
  const [auth, setState] = useState({
    ...initialState,
    token: localStorage.getItem('jwt'),
    user: JSON.parse(localStorage.getItem('user')) || null,
  });

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      tokenLogin(token);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await API.POST('login', { email, password });
      const { token, user } = response;
      setState({ ...auth, email: user.email, isLoggedIn: true, token: token, user });
      localStorage.setItem('jwt', token);
      localStorage.setItem('user', JSON.stringify(user));
      return { success: true };
    } catch (error) {
      setState({ ...auth, loginErrorMessage: error.message || 'Login failed' });
      return { success: false, error: error.message || 'Login failed' };
    }
  };

  const tokenLogin = async (token) => {
    if (!token) {
      console.error('No token provided for verification');
      logout();
      return;
    }

    try {
      const response = await API.POST('login/verifyToken', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.user) {
        setState({
          ...auth,
          email: response.user.email,
          isLoggedIn: true,
          token: token,
          user: response.user,
        });
      } else {
        throw new Error('Invalid response from token verification');
      }
    } catch (e) {
      console.error('Token verification failed:', e);
      logout();
    }
  };

  const logout = async () => {
    try {
      const token = auth.token;
      if (token) {
        // Call the logout API
        await API.POST('login/logout', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Clear localStorage
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');

      // Reset state to initial values
      setState({
        ...initialState,
        loginErrorMessage: null,
      });

      console.log('User logged out successfully');
    }
  };

  const clearErrorMessage = () => setState({ ...auth, loginErrorMessage: null });

  const contextValue = {
    auth,
    login,
    tokenLogin,
    logout,
    clearErrorMessage,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
