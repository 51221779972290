import moment from 'moment';
import districts from './districts';

/**
 * Checks if the given value matches the specified regular expression.
 * @param {string} value - The string value to be checked.
 * @param {RegExp} regex - The regular expression to test against the value.
 * @returns {boolean} True if the value matches the regex, false otherwise.
 */
const check = (value, regex) => value && value.match(regex) != null;

/**
 * Validates an email address.
 * @param {string} email - The email address to be validated.
 * @returns {boolean} True if the email is valid, false otherwise.
 */
export const validateEmail = (email) => {
  return check(email, /\S+@\S+\.\S+/);
};

/**
 * Validates a phone number.
 * @param {string} phoneNumber - The phone number to be validated.
 * @returns {boolean} True if the phone number is valid, false otherwise.
 */
export const validatePhoneNumber = (phoneNumber) => {
  return check(phoneNumber, /^[0-9]{8}$/);
};

/**
 * Validates a name, allowing ÆØÅ, special characters and spaces.
 * @param {string} name - The name to be validated.
 * @returns {boolean} True if the name is valid, false otherwise.
 */
export const validateName = (name) => {
  return check(name, /^[A-Za-z\sæøåÆØÅ,.-]+$/);
};

/**
 * Validates a name input field which can also be empty.
 * @param {string} name - The name input to be validated.
 * @returns {boolean} True if the name input is valid or empty, false otherwise.
 */
export const validateNameInput = (name) => {
  return name.length === 0 || check(name, /^[A-Za-z\sæøåÆØÅ,.-]+$/);
};

/**
 * Validates a phone number input field which can also be empty.
 * @param {string} phoneNumber - The phone number input to be validated.
 * @returns {boolean} True if the phone number input is valid or empty, false otherwise.
 */
export const validatePhoneNumberInput = (phoneNumber) => {
  return phoneNumber.length === 0 || check(phoneNumber, /^([0-9]){0,8}$/);
};

/**
 * Validates a delivery date to be a future date.
 * @param {string|moment} date - The date to be validated.
 * @returns {boolean} True if the date is valid and in the future, false otherwise.
 */
export const validateDeliveryDate = (date, isUrgent) => {
  date = date instanceof moment ? date : moment(date);
  if (!date.isValid()) return false;

  const today = moment();
  const requiredDays = isUrgent ? 0 : 2; // 1 day for urgent deliveries, 3 days for non-urgent

  return date.isAfter(today.add(requiredDays, 'days'), 'day');
};

/**
 * Validates an address input field which can also be empty.
 * @param {string} address - The address input to be validated.
 * @returns {boolean} True if the address input is valid or empty, false otherwise.
 */
export const validateAddressInput = (address) => {
  return address.length === 0 || check(address, /^([A-Za-z\d\sæøåÆØÅ])+$/);
};

/**
 * Validates a street address, allowing certain special characters, numbers, and spaces.
 * @param {string} streetAddress - The street address to be validated.
 * @returns {boolean} True if the street address is valid, false otherwise.
 */
export const validateAddressStreet = (streetAddress) => {
  return check(streetAddress, /^([A-Za-z\d\sæøåÆØÅ,.-])+$/);
};

/**
 * Validates a Norwegian postal code.
 * @param {string} postalCode - The postal code to be validated.
 * @returns {boolean} True if the postal code is valid, false otherwise.
 */
export const validatePostalCode = (postalCode) => {
  return check(postalCode, /^[0-9]{4}$/);
};

/**
 * Validates the name of a postal town, allowing certain special characters, numbers, and spaces.
 * @param {string} postalTown - The postal town name to be validated.
 * @returns {boolean} True if the postal town name is valid, false otherwise.
 */
export const validatePostalTown = (postalTown) => {
  return check(postalTown, /^([A-Za-z\d\sæøåÆØÅ])+$/);
};

/**
 * Validates an address name, allowing certain special characters, numbers, and spaces.
 * @param {string} name - The address name to be validated.
 * @returns {boolean} True if the address name is valid, false otherwise.
 */
export const validateAddressName = (name) => {
  return check(name, /^([A-Za-z\d\sæøåÆØÅ,.-])+$/);
};

/**
 * Validates a complete address object.
 * @param {Object} address - The address object to be validated.
 * @returns {boolean} True if all parts of the address are valid, false otherwise.
 */
export const validateAddress = (address) => {
  if (!validateAddressName(address.name)) return false;
  if (!validateAddressStreet(address.address)) return false;
  if (!validatePostalCode(address.postalCode)) return false;
  if (!validatePostalTown(address.postalTown)) return false;
  return true;
};

/**
 * Validates an array of products, ensuring it's not empty and each product has a positive count.
 * @param {Array} products - The array of products to be validated.
 * @returns {boolean} True if the products array is valid, false otherwise.
 */
export const validateProducts = (products) => {
  return products && products.length > 0 && products.every((product) => product.count > 0);
};

/**
 * Validates district field ensuring it's not empty and value is from the districts array.
 * @param {Array} products - The array of products to be validated.
 * @returns {boolean} True if the products array is valid, false otherwise.
 */
export const validateDistrict = (selectedDistrict) => {
  return districts.includes(selectedDistrict);
};

/**
 * Validates the complete form data.
 * @param {Object} form - The form data to be validated.
 * @returns {boolean} True if the form data is valid, false otherwise.
 */
export const validateForm = (form) => {
  if (!validateDeliveryDate(form.deliveryDate, form.isUrgent)) return false;
  if (!validateName(form.name)) return false;
  if (!validatePhoneNumber(form.phone)) return false;
  if (!validateProducts(form.products)) return false;
  if (form.useManualPickup) return Boolean(form.manualPickupDistrict);
  if (!validateAddress(form.address)) return false;
  return true;
};
