import React, { useState, useEffect } from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, isWeekend, add } from 'date-fns';
import nb from 'date-fns/locale/nb'; // Norwegian Bokmål locale
import en from 'date-fns/locale/en-GB'; // English locale
import { TextField } from '@mui/material';

// Function to add business days, excluding weekends
const addBusinessDays = (date, days) => {
  let result = new Date(date);
  let addedDays = 0;
  while (addedDays < days) {
    result = addDays(result, 1);
    if (!isWeekend(result)) {
      addedDays += 1;
    }
  }
  return result;
};

// Calculate default date
const defaultDate = addBusinessDays(new Date(), 3);
const minDate = add(new Date(), { days: 1 });

const locales = { no: nb, en: en };

/**
 * A Material-UI based DatePicker component localized for Norwegian and English languages.
 * It provides a user-friendly way to pick dates, ensuring the selected date is not earlier than tomorrow.
 *
 * @param {Object} props - The props to pass to the DatePicker.
 * @param {string} props.name - The name of the DatePicker field, used in form structures.
 * @param {Date} props.value - The currently selected date value.
 * @param {Function} props.onChange - Callback function to handle the change event of the date picker.
 * @param {boolean} [props.error] - Optional prop to indicate if the field should display an error state.
 * @returns {React.ReactElement} A localized DatePicker component.
 */
const Datepicker = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState('no'); // Default to Norwegian

  // Effect to update the selected language based on localStorage
  useEffect(() => {
    const language = localStorage.getItem('i18nextLng');
    if (language === 'en') {
      setSelectedLanguage('en');
    } else {
      setSelectedLanguage('no');
    }
  }, []); // This effect runs only once when the component mounts

  const onChange = (newValue) => {
    props.onChange({
      target: {
        name: props.name,
        value: newValue,
      },
    });
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locales[selectedLanguage]} // Use the selected locale from the state
    >
      <MuiDatePicker
        {...props}
        value={props.value || defaultDate}
        minDate={minDate}
        onChange={onChange}
        timezone='Europe/Oslo'
        renderInput={(params) => <TextField {...params} {...props} fullWidth color='primary' error={props.error} />}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
