import API from './api';

/**
 * Submits an order to the API.
 *
 * This function sends an order object to the API's 'ova/orders/externalOrder'
 * endpoint using a POST request. It relies on the API module's POST method
 * for handling the request.
 *
 * @param {Object} order - The order data to be submitted.
 * @returns {Promise<Object>} A promise that resolves to the response from the API.
 */
export const submitOrder = (order) => API.POST('ova/orders/externalOrder', order);
