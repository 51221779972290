const APIBASE = '/api/v1/';

/**
 * Represents an API error.
 *
 * This class extends the native JavaScript Error class
 * to handle API-specific errors, including HTTP status codes
 * and response bodies.
 */
export class ApiError extends Error {
  /**
   * Creates an instance of ApiError.
   *
   * @param {Response} res - The response object from fetch API.
   * @param {Object} body - The parsed JSON body of the response.
   */
  constructor(res, body) {
    super(body.message || res.statusText);
    this.status = res.status;
    this.name = 'ApiError';
    this.body = body;
  }
}

/**
 * Returns the default headers for API requests.
 *
 * This function generates the headers required for API requests,
 * including content type and authorization token if available.
 *
 * @returns {Object} An object containing the headers.
 */
export const getDefaultHeaders = () => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  };
  const token = localStorage.getItem('jwt');
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return headers;
};

/**
 * Handles the response from fetch API requests.
 *
 * This function processes the response from the API, checking for
 * HTTP status codes and converting the response to JSON. If the
 * response indicates an error, it throws an ApiError.
 *
 * @param {Response} res - The response object from the fetch API.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
const responseHandler = async (res) => {
  // no content
  if (res.status === 204) return { httpStatus: res.status };

  const data = await res.json();

  if (!res.ok) {
    throw new ApiError(res, data);
  }

  return data;
};

/**
 * Object containing methods for making API requests.
 *
 * This object provides functions for sending GET and POST requests
 * to the API. It uses the API base URL, attaches the necessary headers,
 * and handles the response.
 */
export default {
  GET: (path) => fetch(APIBASE + path, { method: 'GET', headers: getDefaultHeaders() }).then(responseHandler),
  POST: (path, body) =>
    fetch(APIBASE + path, {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(body),
    }).then(responseHandler),
};
