import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Button = styled.input`
  align-self: center;
  margin: 2em 0.5em;
  border: ${(props) => (props.disabled || props.contained ? 'none' : '1px solid #28e')};
  border-radius: 8px;
  padding: 1em;
  font-size: 1.2em;
  background: #28e;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

/**
 * OrderSuccess component displays a confirmation message for a successful order and provides a button to start a new order.
 *
 * @param {Object} props - The props of the component.
 * @param {string} props.orderId - The unique identifier of the successfully placed order.
 * @param {Function} props.startNewOrder - Function to initiate the process of creating a new order.
 * @returns {React.ReactElement} The OrderSuccess component.
 */
export const OrderSuccess = ({ orderId, startNewOrder }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2>{t('order_success.order_received', { orderId })}</h2>
      <Button type='button' value={t('order_success.new_order_button')} onClick={startNewOrder} />
    </Wrapper>
  );
};
