import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper, Avatar, TextField, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import AuthContext from '../context/AuthContext';

const paperStyle = {
  padding: 20,
  maxWidth: 300,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1em',
  marginTop: '160px',
  marginBottom: '160px',
};
const avatarStyle = { backgroundColor: '#1bbd7e' };

/**
 * Login component provides a user interface for authentication.
 * It includes form fields for email and password, and a button to submit the login request.
 * The component uses AuthContext for managing authentication state and handling login functionality.
 * It also maintains local state for form fields and a loading indicator.
 *
 * @returns {React.ReactElement} The Login component.
 */

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { auth, login } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onLogin = async (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    const result = await login(email, password);
    setIsLoading(false);
    if (!result.success) {
      setError(result.error || t('messages.failed'));
    } else {
      setSuccess(true);
    }
  };

  const onChange = (ev) => {
    if (ev.target.name === 'email') setEmail(ev.target.value);
    else if (ev.target.name === 'password') setPassword(ev.target.value);
  };

  return (
    <Grid>
      <form onSubmit={onLogin}>
        <Paper elevation={10} style={paperStyle}>
          <Grid align='center'>
            <Avatar style={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <h2>{t('login.title')}</h2>
          </Grid>
          <TextField
            error={!!auth.loginErrorMessage}
            helperText={auth.loginErrorMessage}
            name='email'
            value={email}
            onChange={onChange}
            label={t('login.email')}
            placeholder={t('login.email_placeholder')}
            fullWidth
            required
          />
          <TextField
            error={!!auth.loginErrorMessage}
            name='password'
            value={password}
            onChange={onChange}
            label={t('login.password')}
            placeholder={t('login.password_placeholder')}
            type='password'
            fullWidth
            required
          />
          {isLoading ? (
            <Button type='submit' color='primary' variant='contained' fullWidth disabled>
              {t('login.loading')}
            </Button>
          ) : (
            <Button type='submit' color='primary' variant='contained' fullWidth>
              {t('login.login_button')}
            </Button>
          )}
        </Paper>
      </form>
    </Grid>
  );
};

export default Login;
