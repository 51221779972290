import braceletset from '../../public/productImages/braceletset.jpg';
import comfortelement from '../../public/productImages/comfortelement.jpg';
import key from '../../public/productImages/key.jpg';
import mobile from '../../public/productImages/mobile.jpg';
import tapemeasurer from '../../public/productImages/tapemeasurer.jpg';
import usbcable from '../../public/productImages/usbcable.jpg';
import wiredcharger from '../../public/productImages/wiredcharger.jpg';
import wirelesscharger from '../../public/productImages/wirelesscharger.jpg';

/**
 * Defines types of products.
 */
export const productType = {
  BRACELET: 'bracelet',
  BATTERY: 'battery',
  DOMFELT_MOBIL: 'domfeltMobil',
  OTHER: 'other',
};

/**
 * Enumerates various statuses for OVA.
 */
export const OVA_STATUS = {
  DEFAULT: 'default',
  BROKEN: 'broken',
  SERVICE: 'service',
  COMPLETE: 'complete',
  VERIFIED: 'verified',
  TO_VERIFY: 'to_verify',
  TO_VERIFY_WITH_ISSUE: 'to_verify_issue',
  ARMED: 'armed',
};

/**
 * Defines actions that can be taken on an OVA
 */
export const OVA_ACTIONS = {
  ADD_EQUIPMENT: 'add_equipment',
  REMOVE_EQUIPMENT: 'remove_equipment',
  EDIT: 'edit',
  SHIP: 'ship',
};

/**
 * Specifies the environment where the OVA system operates.
 */
export const OVA_ENV = {
  TUPP_UTV: 'tupp_utv',
  TUPP_TEST: 'tupp_test',
  TUPP_PRE: 'tupp_pre',
  PROD: 'prod',
};

/**
 * NAME: Name of the bracelet model
 * MANUFACTURER: Name of the manufacturer
 * EQUIPMENT: Equipment used by the bracelet model
 */
export const BRACELETS = {
  DEFAULT: {
    NAME: 'Default',
    MANUFACTURER: 'Geosatis',
    EQUIPMENT: {
      EXTERNAL_CHARGER: 'external_charger',
      KEY: 'key',
      WIRED_CHARGER: 'wired_charger',
    },
    BRX: {
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
    },
  },
  DOMFELTMOBIL: {
    NAME: 'Domfeltmobil',
    MANUFACTURER: 'NONE',
    EQUIPMENT: {},
  },
};

/**
 * The default bracelet model name.
 */
export const BRACELET_DEFAULT = BRACELETS.DEFAULT.NAME;

/**
 * Units related to bracelet models.
 */
export const BRACELET_UNITS = {
  SET_S: 'set_s',
  SET_M: 'set_m',
  SET_L: 'set_l',
  SET_XL: 'set_xl',
};

/**
 * Additional units associated with the bracelets.
 */
export const EXTRA_UNITS = {
  KEY: BRACELETS.DEFAULT.EQUIPMENT.KEY,
  EXTERNAL_CHARGER: BRACELETS.DEFAULT.EQUIPMENT.EXTERNAL_CHARGER,
  WIRED_CHARGER: BRACELETS.DEFAULT.EQUIPMENT.WIRED_CHARGER,
};

/**
 * Defines the domfeltmobil unit.
 */
export const DOMFELT_MOBIL = {
  DOMFELTMOBIL: 'Domfeltmobil',
};

/**
 * Other units that might be included in an order.
 */
export const OTHER_UNITS = {
  EXTERNAL_CHARGER: BRACELETS.DEFAULT.EQUIPMENT.EXTERNAL_CHARGER,
  WIRED_CHARGER: BRACELETS.DEFAULT.EQUIPMENT.WIRED_CHARGER,
  KEY: BRACELETS.DEFAULT.EQUIPMENT.KEY,
};

/**
 * Accessories that can be included in an order.
 */
export const ACCESSORIES = {
  USB_CHARGER: 'usb_charger',
  MEASURING_TAPE: 'measuring_tape',
  COMFORT_ELEMENT_S: 'comfort_s',
  COMFORT_ELEMENT_M: 'comfort_m',
  COMFORT_ELEMENT_L: 'comfort_l',
  COMFORT_ELEMENT_XL: 'comfort_xl',
};

/**
 * All types of units that can be included in an order.
 */
export const ORDER_UNITS = {
  ...BRACELET_UNITS,
  ...DOMFELT_MOBIL,
  ...OTHER_UNITS,
  ...ACCESSORIES,
};

/**
 * Descriptions for all order units.
 */
const productDescriptions = {
  [ORDER_UNITS.SET_S]: 'Inneholder 1 eksternt batteri, 1 kablet lenkelader, 2 komfortelementer, 1 USB-lader',
  [ORDER_UNITS.SET_M]: 'Inneholder 1 eksternt batteri, 1 kablet lenkelader, 2 komfortelementer, 1 USB-lader',
  [ORDER_UNITS.SET_L]: 'Inneholder 1 eksternt batteri, 1 kablet lenkelader, 2 komfortelementer, 1 USB-lader',
  [ORDER_UNITS.SET_XL]: 'Inneholder 1 eksternt batteri, 1 kablet lenkelader, 2 komfortelementer, 1 USB-lader',
  [BRACELETS.DEFAULT.EQUIPMENT.EXTERNAL_CHARGER]: 'USB-lader og kabel medfølger',
  [BRACELETS.DEFAULT.EQUIPMENT.WIRED_CHARGER]: 'Lader med stikkontakt',
  [BRACELETS.DEFAULT.EQUIPMENT.KEY]: 'USB-lader og kabel medfølger',
  [ACCESSORIES.USB_CHARGER]: 'Kabel + AC-adapter',
  [ACCESSORIES.MEASURING_TAPE]: 'Målebånd',
  [ACCESSORIES.COMFORT_ELEMENT_S]: 'Komfortelement til Small lenke',
  [ACCESSORIES.COMFORT_ELEMENT_M]: 'Komfortelement til Medium lenke',
  [ACCESSORIES.COMFORT_ELEMENT_L]: 'Komfortelement til Large lenke',
  [ACCESSORIES.COMFORT_ELEMENT_XL]: 'Komfortelement til Ekstra Large lenke',
};

/**
 * Maps product units to their sizes.
 */
const productSize = {
  [ORDER_UNITS.SET_S]: 'Small',
  [ORDER_UNITS.SET_M]: 'Medium',
  [ORDER_UNITS.SET_L]: 'Large',
  [ORDER_UNITS.SET_XL]: 'Extra Large',
};

/**
 * Maps product units to their image URLs.
 */
export const productImage = {
  [ORDER_UNITS.SET_S]: braceletset,
  [ORDER_UNITS.SET_M]: braceletset,
  [ORDER_UNITS.SET_L]: braceletset,
  [ORDER_UNITS.SET_XL]: braceletset,
  [BRACELETS.DEFAULT.EQUIPMENT.EXTERNAL_CHARGER]: wirelesscharger,
  [BRACELETS.DEFAULT.EQUIPMENT.WIRED_CHARGER]: wiredcharger,
  [BRACELETS.DEFAULT.EQUIPMENT.KEY]: key,
  [ACCESSORIES.USB_CHARGER]: usbcable,
  [ACCESSORIES.MEASURING_TAPE]: tapemeasurer,
  [ACCESSORIES.COMFORT_ELEMENT_S]: comfortelement,
  [ACCESSORIES.COMFORT_ELEMENT_M]: comfortelement,
  [ACCESSORIES.COMFORT_ELEMENT_L]: comfortelement,
  [ACCESSORIES.COMFORT_ELEMENT_XL]: comfortelement,
  [ORDER_UNITS.DOMFELTMOBIL]: mobile,
};

/**
 * Mapping of equipment types to their readable names.
 */
const equipmentTypeMap = {
  [BRACELETS.DEFAULT.EQUIPMENT.EXTERNAL_CHARGER]: 'Eksternt batteri',
  [BRACELETS.DEFAULT.EQUIPMENT.WIRED_CHARGER]: 'Lader med kabel',
  [BRACELETS.DEFAULT.EQUIPMENT.KEY]: 'KEY',
};

/**
 * Returns the readable name for a given equipment type.
 * @param {string} type - The type of the equipment.
 * @returns {string} The readable name of the equipment type.
 */
export function getReadableEquipmentType(type) {
  return equipmentTypeMap[type] || type;
}

/**
 * Mapping of order units to their readable names.
 */
const ovaOrderUnitsMap = {
  [ORDER_UNITS.SET_S]: 'Lenkesett',
  [ORDER_UNITS.SET_M]: 'Lenkesett',
  [ORDER_UNITS.SET_L]: 'Lenkesett',
  [ORDER_UNITS.SET_XL]: 'Lenkesett',
  [ORDER_UNITS.KEY]: 'KEY-verktøy',
  [ORDER_UNITS.EXTERNAL_CHARGER]: equipmentTypeMap[BRACELETS.DEFAULT.EQUIPMENT.EXTERNAL_CHARGER],
  [ORDER_UNITS.WIRED_CHARGER]: equipmentTypeMap[BRACELETS.DEFAULT.EQUIPMENT.WIRED_CHARGER],
  [ORDER_UNITS.DOMFELTMOBIL]: 'Domfeltmobil',
  [ACCESSORIES.USB_CHARGER]: 'USB-Lader',
  [ACCESSORIES.MEASURING_TAPE]: 'Målebånd',
  [ACCESSORIES.COMFORT_ELEMENT_S]: 'Komfortelement til Small lenke',
  [ACCESSORIES.COMFORT_ELEMENT_M]: 'Komfortelement til Medium lenke',
  [ACCESSORIES.COMFORT_ELEMENT_L]: 'Komfortelement til Large lenke',
  [ACCESSORIES.COMFORT_ELEMENT_XL]: 'Komfortelement til Ekstra Large lenke',
};

/**
 * Returns the readable name for a given order unit.
 * @param {string} type - The type of the order unit.
 * @returns {string} The readable name of the order unit.
 */
export function getReadableOrderUnit(type) {
  return ovaOrderUnitsMap[type] || type;
}

/**
 * Retrieves a map of all order units with their readable names.
 * @returns {Object} An object with keys as unit types and values as readable names.
 */
export function getAllReadableOrderUnits() {
  const units = {};
  Object.entries(ORDER_UNITS).forEach(([key, value]) => {
    units[key] = getReadableOrderUnit(value);
  });
  return units;
}

/**
 * Retrieves a map of all bracelet units with their readable names.
 * @returns {Object} An object with keys as bracelet unit types and values as readable names.
 */
export function getAllReadableBraceletUnits() {
  const units = {};
  Object.entries(BRACELET_UNITS).forEach(([key, value]) => {
    units[key] = getReadableOrderUnit(value);
  });
  return units;
}

/**
 * Retrieves a map of all domfelt mobile units with their readable names.
 * @returns {Object} An object with keys as domfelt mobile unit types and values as readable names.
 */
export function getAllReadableDomfeltMobil() {
  const units = {};
  Object.entries(DOMFELT_MOBIL).forEach(([key, value]) => {
    units[key] = getReadableOrderUnit(value);
  });
  return units;
}

/**
 * Retrieves a map of all other units with their readable names.
 * @returns {Object} An object with keys as other unit types and values as readable names.
 */
export function getAllReadableOtherUnits() {
  const units = {};
  Object.entries(OTHER_UNITS).forEach(([key, value]) => {
    units[key] = getReadableOrderUnit(value);
  });
  return units;
}

/**
 * Retrieves a map of all accessory units with their readable names.
 * @returns {Object} An object with keys as accessory unit types and values as readable names.
 */
export function getAllReadableAccessories() {
  const units = {};
  Object.entries(ACCESSORIES).forEach(([key, value]) => {
    units[key] = getReadableOrderUnit(value);
  });
  return units;
}

/**
 * Retrieves an array of all available products with details.
 * @returns {Array} An array of product objects with details like id, name, type, count, and description.
 */
export function getAllAvailableProducts() {
  const bracelets = Object.entries(getAllReadableBraceletUnits()).map(([unitKey, readableName]) => {
    const size = productSize[ORDER_UNITS[unitKey]] || '';
    return {
      id: ORDER_UNITS[unitKey],
      name: readableName,
      img: productImage[ORDER_UNITS[unitKey]],
      type: productType.BRACELET,
      size: size,
      count: 1,
      description: productDescriptions[ORDER_UNITS[unitKey]] || '',
    };
  });

  const domfeltMobil = Object.entries(getAllReadableDomfeltMobil()).map(([unitKey, readableName]) => ({
    id: ORDER_UNITS[unitKey],
    name: readableName,
    img: productImage[ORDER_UNITS[unitKey]],
    type: productType.DOMFELT_MOBIL,
    count: 1,
    description: productDescriptions[ORDER_UNITS[unitKey]] || '',
  }));

  const other = Object.entries(getAllReadableOtherUnits()).map(([unitKey, readableName]) => ({
    id: ORDER_UNITS[unitKey],
    name: readableName,
    img: productImage[ORDER_UNITS[unitKey]],
    type: productType.OTHER,
    count: 1,
    description: productDescriptions[ORDER_UNITS[unitKey]] || '',
  }));

  const accessories = Object.entries(getAllReadableAccessories()).map(([unitKey, readableName]) => ({
    id: ORDER_UNITS[unitKey],
    name: readableName,
    img: productImage[ORDER_UNITS[unitKey]],
    type: productType.OTHER,
    count: 1,
  }));

  return [...bracelets, ...domfeltMobil, ...other, ...accessories];
}
