import React, { useState } from 'react';
import { Button, Divider, IconButton, InputAdornment, Paper, Stack, TextField } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../common/validators';

/**
 * A React component for managing email recipients and CC addresses.
 * It displays the main recipient and allows adding or removing CC addresses.
 * New CC addresses can be added through an input field with email validation.
 *
 * @param {Object} props - The component props.
 * @param {string} props.to - The main recipient's email address.
 * @param {string[]} props.cc - An array of CC email addresses.
 * @param {Function} props.onAddCC - Callback function to handle adding a new CC address.
 * @param {Function} props.onRemoveCCIndex - Callback function to handle removing a CC address by index.
 * @returns {React.ReactElement} The rendered component for email recipients management.
 */
const EmailRecipients = ({ to, cc, onAddCC, onRemoveCCIndex }) => {
  const { t } = useTranslation();
  const [newEmail, setNewEmail] = useState('');
  const [showEmailInput, setShowEmailInput] = useState(true);

  const onSubmitNewEmail = () => {
    onAddCC(newEmail);
    setNewEmail('');
    setShowEmailInput(false);
  };

  return (
    <Paper elevation={2}>
      <Stack>
        <Stack style={{ padding: '0px 16px' }} direction='row' justifyContent='space-between'>
          <p>{t('email_recipients.recipient')}</p>
          <p>{to}</p>
        </Stack>
        <Divider flexItem />
        {cc.map((c, i) => {
          return i === 0 ? (
            <Stack style={{ padding: '0px 16px' }} direction='row' justifyContent='space-between'>
              <p>{t('email_recipients.cc')}</p>
              <Stack direction='row' justifyContent='flex-end'>
                <p>{cc[0]}</p>
                <IconButton onClick={() => onRemoveCCIndex(0)}>
                  <Close />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <Stack key={c} style={{ padding: '0px 16px' }} direction='row' justifyContent='flex-end'>
              <p>{c}</p>
              <IconButton onClick={() => onRemoveCCIndex(i)}>
                <Close />
              </IconButton>
            </Stack>
          );
        })}
        {showEmailInput ? (
          <Stack style={{ padding: '0px 16px' }} direction='row' justifyContent='flex-end'>
            <TextField
              value={newEmail}
              onChange={(ev) => setNewEmail(ev.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') onSubmitNewEmail();
              }}
              label={t('email_recipients.email')}
              placeholder={t('email_recipients.email')}
              fullWidth
              error={!validateEmail(newEmail)}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        setNewEmail('');
                        setShowEmailInput(false);
                      }}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant='contained' disabled={!validateEmail(newEmail)} type='submit' onClick={onSubmitNewEmail}>
              {t('email_recipients.confirm')}
            </Button>
          </Stack>
        ) : (
          <Stack style={{ padding: '0px 16px' }} direction='row' justifyContent='flex-end'>
            <Button variant='text' onClick={() => setShowEmailInput(true)}>
              {t('email_recipients.add_recipient')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default EmailRecipients;
